import React, { useEffect } from 'react'
import withAdminNavigate from 'hoc/withAdminNavigate'
import { Block } from 'baseui/block'
import { H5, Label2 } from 'baseui/typography'
import ReportBox from 'components/ReportBox'
import withDashboard from 'hoc/withDashboard'
import { get } from 'lodash'
import moment from 'moment'
import Loader from 'components/Loader'

import PieGraphSection from 'components/PieGraphSection'
import BarGraphSection from 'components/BarGraphSection'
import ScrollTable from 'components/ScrollTable'
import TopFiveTable from 'components/TopFiveTable'
import CloudApi from 'cloudApi/api'

import {
  getPostJobCount,
  getPostedJobStaticYear,
  getPostedJobLastest,
  getPostedJobStaticFaculty,
  getPostedJobStaticTag,
  setActiveStaticYear,
  setActiveStaticFacultyYear,
} from '../actions/dashboard'

const PostJob = ({ dashboardState, dashboardDispatch }) => {
  const [ faculties, setFaculties ] = React.useState([])
  const {
    postJobSummary,
    postedJobStaticYear,
    postedJobLast,
    postedJobStaticFaculty,
    postedJobTag,
    activeStaticYear,
    activeStaticFacultyYear,
  } = dashboardState
  useEffect(() => {
    const presentYear =
      parseInt(moment().format('YYYYY')) +
      (moment().isAfter(moment().month('7')) ? 543 : 542)
    if (!postJobSummary) {
      getPostJobCount(dashboardDispatch)
    }
    if (!postedJobStaticYear) {
      getPostedJobStaticYear(dashboardDispatch, presentYear)
    }
    if (!postedJobLast) {
      getPostedJobLastest(dashboardDispatch)
    }
    if (!postedJobStaticFaculty) {
      getPostedJobStaticFaculty(dashboardDispatch, presentYear)
    }
    if (!postedJobTag) {
      getPostedJobStaticTag(dashboardDispatch)
    }
    CloudApi.getDepartments().then(data => {
      const facultyDataToShow = []
      data.forEach(faculty => {
        faculty.faculty.forEach(facultyData =>
          facultyDataToShow.push({
            id: facultyData.faculty_id,
            facultyName: `${facultyData.thai_name}`,
          })
        )
      })
      setFaculties(facultyDataToShow)
    })
    if (!activeStaticYear) {
      setActiveStaticYear(dashboardDispatch, presentYear.toString())
    }
    if (!activeStaticFacultyYear) {
      setActiveStaticFacultyYear(dashboardDispatch, presentYear.toString())
    }
  }, [])

  return (
    <Block width="100%">
      <H5 marginTop="0px" marginBottom="10px">
        Posted Job Report
      </H5>
      <Block display="flex" justifyContent="space-between">
        <ReportBox
          title={'งานที่ประกาศ'}
          value={postJobSummary ? postJobSummary.total_job : ''}
          width={'28%'}
          type={'summary'}
        />
        <ReportBox
          title={'งานที่ยังเปิดรับอยู่'}
          value={postJobSummary ? postJobSummary.open_job : ''}
          width={'28%'}
          type={'success'}
        />
        <ReportBox
          title={'งานที่ปิดรับแล้ว'}
          value={postJobSummary ? postJobSummary.closed_job : ''}
          width={'28%'}
          type={'approve'}
        />
      </Block>
      <Block marginTop="30px" display="flex" justifyContent="space-between">
        <Block width="60%">
          <Block>
            {get(postedJobStaticYear, activeStaticYear) && activeStaticYear ? (
              <BarGraphSection
                title={'งานที่เปิดรับ และปิดรับในแต่ละเดือน'}
                data={get(postedJobStaticYear, activeStaticYear)}
                keys={[ 'total_job', 'closed_job' ]}
                year={activeStaticYear}
                setYear={year => {
                  !get(postedJobStaticYear, year) &&
                  getPostedJobStaticYear(dashboardDispatch, year)
                  setActiveStaticYear(dashboardDispatch, year)
                }}
              />
            ) : (
              <Loader/>
            )}
          </Block>
          <Block
            padding="16px"
            backgroundColor="white"
            style={{ borderTop: '4px solid #fd7c2e' }}
            marginTop="30px"
          >
            <Label2 style={{ fontWeight: 'bold', marginBottom: 16 }}>
              งานเปิดรับล่าสุด
            </Label2>
            {postedJobLast &&
            postedJobLast.length > 0 &&
            faculties.length > 0 ? (
              <TopFiveTable
                widths={[ '20%', '60%', '20%' ]}
                data={postedJobLast.map(postedJob => ({
                  ...postedJob,
                  facultyName: faculties.find(
                    faculty => faculty.id === postedJob.faculty
                  ).facultyName,
                  created_at: moment(postedJob.created_at).format('D MMM YYYY'),
                }))}
                keys={[ 'created_at', 'title', 'facultyName' ]}
                headers={[ 'วันที่ประกาศ', 'ชื่องาน', 'คณะ/หน่วยงาน' ]}
              />
            ) : (
              <Loader height={50} size={'50px'}/>
            )}
          </Block>
        </Block>
        <Block width="38%">
          {get(postedJobStaticFaculty, activeStaticFacultyYear) ? (
            <PieGraphSection
              data={get(postedJobStaticFaculty, activeStaticFacultyYear).map(
                data => {
                  if (data.faculty === '')
                    return {
                      ...data,
                      faculty: 'อื่นๆ',
                    }
                  return data
                }
              )}
              dataKey={'count'}
              nameKey={'faculty'}
              title={'งานที่ประกาศโดยแบ่งตามคณะ / หน่วยงาน'}
              year={activeStaticFacultyYear}
              setYear={year => {
                !get(postedJobStaticFaculty, year) &&
                getPostedJobStaticYear(dashboardDispatch, year)
                setActiveStaticFacultyYear(dashboardDispatch, year)
              }}
            />
          ) : (
            <Loader/>
          )}
          <Block
            marginTop="30px"
            padding="16px"
            backgroundColor="white"
            style={{ borderTop: '4px solid #fd7c2e' }}
          >
            <Label2 style={{ fontWeight: 'bold', marginBottom: 16 }}>
              จำนวนงานแบ่งตามประเภท
            </Label2>
            {postedJobTag && postedJobTag.length > 0 ? (
              <ScrollTable
                headers={[ 'ประเภทงาน', 'จำนวน' ]}
                data={postedJobTag}
                keys={[ 'tag', 'count' ]}
                tag
              />
            ) : (
              <Loader height={50} size={'50px'}/>
            )}
          </Block>
        </Block>
      </Block>
    </Block>
  )
}

export default withAdminNavigate(withDashboard(PostJob))
